.Banner {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  p, h2 {
    margin: 0 0 5px;
  }

  &.horizontal {}

  &.vertical {}

  &_title {
    font-weight: bold;
    text-align: center;
    font-size: 16px;
  }

  &_description {
    color: #303030;
    font-size: 14px;
  }

  &_content {
    font-size: 12px;
  }
}
