$green: #8ACA2B;
$blue: #59D5FF;
$grey: #aaa;
$black: #696969;

.Counter {
  &_title {
    font-size: 24px;
    font-weight: bold;
    text-transform: uppercase;
    padding: 10px 20px;
    background-color: #e2e2e2;
  }
  &_wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    .row {
      width: 100%;
      display: flex;
      &-center {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      &-between {
        display: flex;
        align-items: center;
        justify-content: space-between;
        @media screen and (max-width: 480px){
          flex-direction: column;
        }
      }
    }

    .column {
      display: flex;
      flex-direction: column;
      &-center {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
      }
    }

    p {
      span {
        display: block;
        &.title {
          font-size: 14px;
          font-weight: bold;
          text-transform: uppercase;
          display: flex;
          align-items: center;
          margin-bottom: 5px;
          white-space: nowrap;
          img {
            margin-left: 5px;
            width: 25px;
            height: auto;
          }
        }
        &.value {
          font-size: 2em;
          font-weight: bold;
          padding: 5px 10px;
          background-color: #e2e2e2;
        }
      }
    }

    .green {
      color: $green;
    }
    .grey {
      color: $grey;
    }
    .black {
      color: $black;
    }
    .red {
      color: red;
    }
    .blue {
      color: $blue;
    }
  }

  .w {
    &-100 {
      width: 100%;
    }
    &-max {
      max-width: 600px;
    }
  }
}
