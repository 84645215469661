.Loader {
  display: flex;
  align-items: center;
  justify-content: center;
  &_title {
    margin: 20px;
  }

  &_spinner {
    width: 40px;
    height: 40px;
    border-width: 2px 2px 2px 0;
    border-color: black;
    border-style: solid;
    border-radius: 50%;
    animation: 2s infinite spin;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(180deg);
  }
}
