.App {
  text-align: center;
  justify-content: center;
  min-height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 1200px;
  margin: 0 auto;
  .grid {
    width: 100%;
    height: calc(100vh - 20px);
    display: grid;
    grid-gap: 10px;
    grid-template-areas: "topBanner"
                         "title"
                         "counter"
                         "map"
                         "bottomText";
    &_topBanner {
      grid-area: topBanner;
    }

    &_title {
      grid-area: title;
      font-size: 24px;
      font-weight: bold;
      text-transform: uppercase;
      padding: 10px 20px;
      background-color: #e2e2e2;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      span {
        display: block;
      }
      .uppercase {
        text-transform: uppercase;
      }
    }

    &_counter {
      grid-area: counter;
    }

    &_map {
      grid-area: map;
    }

    &_bottomText {
      grid-area: bottomText;
      font-size: 10px;
      padding: 10px;
      text-align: center;
      a {
        margin: 0 3px;
      }
    }
  }
}
