$pathDefault: #395C7D;
$pathXs: #535C7C;
$pathSm: #655B7C;
$pathMd: #7B5E7D;
$pathLg: #9C6580;
$pathXl: #BD6B84;

.Map {
  padding: 10px;
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: 768px){
    flex-direction: column;
  }

  a {
    text-decoration: none;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &_left {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &_gif, &_img {
    height: auto;
    width: 100%;
  }

  &_btn {
    margin: 10px;
    padding: 10px 20px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    text-transform: uppercase;
    text-decoration: none;
    font-weight: bold;
    color: #fff;
    background-color: #2A68B2;
    transition: 250ms ease-in-out box-shadow;
    &:hover {
      cursor: pointer;
      box-shadow: 0 0 10px rgba(0,0,0,.3);
    }
  }
}
