.Counter_title{font-size:24px;font-weight:bold;text-transform:uppercase;padding:10px 20px;background-color:#e2e2e2}.Counter_wrap{display:flex;flex-direction:column;align-items:center;justify-content:space-between}.Counter_wrap .row{width:100%;display:flex}.Counter_wrap .row-center{display:flex;align-items:center;justify-content:center}.Counter_wrap .row-between{display:flex;align-items:center;justify-content:space-between}@media screen and (max-width: 480px){.Counter_wrap .row-between{flex-direction:column}}.Counter_wrap .column{display:flex;flex-direction:column}.Counter_wrap .column-center{display:flex;align-items:center;justify-content:center;flex-direction:column}.Counter_wrap p span{display:block}.Counter_wrap p span.title{font-size:14px;font-weight:bold;text-transform:uppercase;display:flex;align-items:center;margin-bottom:5px;white-space:nowrap}.Counter_wrap p span.title img{margin-left:5px;width:25px;height:auto}.Counter_wrap p span.value{font-size:2em;font-weight:bold;padding:5px 10px;background-color:#e2e2e2}.Counter_wrap .green{color:#8ACA2B}.Counter_wrap .grey{color:#aaa}.Counter_wrap .black{color:dimgray}.Counter_wrap .red{color:red}.Counter_wrap .blue{color:#59D5FF}.Counter .w-100{width:100%}.Counter .w-max{max-width:600px}

.Loader{display:flex;align-items:center;justify-content:center}.Loader_title{margin:20px}.Loader_spinner{width:40px;height:40px;border-width:2px 2px 2px 0;border-color:black;border-style:solid;border-radius:50%;-webkit-animation:2s infinite spin;animation:2s infinite spin}@-webkit-keyframes spin{from{transform:rotate(0deg)}to{transform:rotate(180deg)}}@keyframes spin{from{transform:rotate(0deg)}to{transform:rotate(180deg)}}

.Map{padding:10px;display:flex;justify-content:space-between}@media screen and (max-width: 768px){.Map{flex-direction:column}}.Map a{text-decoration:none;display:flex;flex-direction:column;align-items:center}.Map_left{display:flex;flex-direction:column;align-items:center;justify-content:center}.Map_gif,.Map_img{height:auto;width:100%}.Map_btn{margin:10px;padding:10px 20px;border-radius:20px;display:flex;align-items:center;justify-content:center;text-align:center;text-transform:uppercase;text-decoration:none;font-weight:bold;color:#fff;background-color:#2A68B2;transition:250ms ease-in-out box-shadow}.Map_btn:hover{cursor:pointer;box-shadow:0 0 10px rgba(0,0,0,0.3)}

.Banner{display:flex;justify-content:center;align-items:center;flex-direction:column}.Banner p,.Banner h2{margin:0 0 5px}.Banner_title{font-weight:bold;text-align:center;font-size:16px}.Banner_description{color:#303030;font-size:14px}.Banner_content{font-size:12px}

.App{text-align:center;justify-content:center;min-height:100vh;display:flex;align-items:center;flex-direction:column;max-width:1200px;margin:0 auto}.App .grid{width:100%;height:calc(100vh - 20px);display:grid;grid-gap:10px;grid-template-areas:"topBanner" "title" "counter" "map" "bottomText"}.App .grid_topBanner{grid-area:topBanner}.App .grid_title{grid-area:title;font-size:24px;font-weight:bold;text-transform:uppercase;padding:10px 20px;background-color:#e2e2e2;display:flex;align-items:center;justify-content:center;flex-direction:column}.App .grid_title span{display:block}.App .grid_title .uppercase{text-transform:uppercase}.App .grid_counter{grid-area:counter}.App .grid_map{grid-area:map}.App .grid_bottomText{grid-area:bottomText;font-size:10px;padding:10px;text-align:center}.App .grid_bottomText a{margin:0 3px}

body{margin:0;font-family:-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale}code{font-family:source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace}

